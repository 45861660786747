window.songs = [
  {
    title: 'Pink Floyd - Money',
    file: 'audio/Money_Original.mp3',
    fileVrx: 'audio/Money_Virtual.mp3',
  },
  {
    title: 'Pink Floyd - Breathe',
    file: 'audio/02 Breathe Original.wav',
    fileVrx: 'audio/02 Breathe Virtual.wav',
  },
  {
    title: 'Miles Davis - So What',
    file: 'audio/davis-stereo.mp3',
    fileVrx: 'audio/davis-virtual.mp3',
  },
  {
    title: 'Vulfpeck - Corey Wong',
    file: 'audio/vulf-stereo.mp3',
    fileVrx: 'audio/vulf-virtual.mp3',
  }, 
  {
    title: 'James Taylor - You-ve Got a Friend',
    file: 'audio/taylor-stereo.mp3',
    fileVrx: 'audio/taylor-virtual.mp3',
  }, 
];