/**
 * @param {HTMLAudioElement} original 
 * @param {HTMLAudioElement} virtual 
 */
function AudioController (originalUrl, virtualUrl) {
  /**
   * Will be set later for the setInterval instance
   */
  this.intervalId = undefined;

  this.original = new Audio(originalUrl);
  this.virtual = new Audio(virtualUrl);

  this.animateTransition = function(audioToStop, audioToStart) {
    clearInterval(this.intervalId);
    audioToStart.currentTime = audioToStop.currentTime;
    audioToStart.volume = 0;
    audioToStop.volume = 1;
    audioToStart.play();
    audioToStop.play();
    this.intervalId = setInterval(transitionFunction.bind(this), 1);

    function transitionFunction () {
      // console.log(`audioToStart vol`, audioToStart.volume);
      if (audioToStart.volume >= 0.99) {
        clearInterval(this.intervalId);
        audioToStop.pause();
      } else {
        audioToStart.volume = audioToStart.volume + 0.005
        audioToStop.volume = audioToStop.volume - 0.005
      }
    }
  }

  this.playOriginal = function() {
    this.animateTransition(this.virtual, this.original);
  }

  this.playVirtual = function() {
    this.animateTransition(this.original, this.virtual);
  }

  this.seekTo = function(time) {
    this.original.currentTime = time;
    this.virtual.currentTime = time;
  }

  this.pauseAll = function() {
    if (this.original.paused) {
      this.original.currentTime = this.virtual.currentTime
    } else {
      this.virtual.currentTime = this.original.currentTime
    }

    this.original.pause();
    this.virtual.pause();
  }

  this.resetAll = function() {
    this.original.pause()
    this.virtual.pause()
    this.original.currentTime = 0
    this.virtual.currentTime = 0
  }
}

window.AudioController = AudioController;
