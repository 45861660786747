var choo = require('choo');
var html = require('choo/html');
var Component = require('choo/component')

require('./lib/audio-controller');


function playerStore (state, emitter) {
  state.playingSong = undefined
  state.playing = false

  emitter.on('play', function (song) {
    state.playingSong = song;
    emitter.emit('render');
  })

  emitter.on('pause', function () {
    state.playingSong = undefined;
    emitter.emit('render');
  })
}

var app = choo({ href: false });
if (typeof devtools !== 'undefined') {
  app.use(devtools())
}
app.use(playerStore);
app.route('/*', mainView);
app.mount('#demo-audio-app');

class ProgressComponent extends Component {
  constructor (audioController, playing) {
    super();
    this.audioController = audioController;
    this.playing = playing;

    this.bindTimeUpdate();
  }

  load() {
    this.bindInputOnChange();
  }

  bindTimeUpdate() {
    this.audioController.original.addEventListener('timeupdate', (e) => {
      let currentProgress = (this.audioController.original.currentTime / this.audioController.original.duration) * 100;
      this.setInputCurrentProgress(currentProgress);
    });
    this.audioController.virtual.addEventListener('timeupdate', (e) => {
      let currentProgress = (this.audioController.virtual.currentTime / this.audioController.virtual.duration) * 100;
      this.setInputCurrentProgress(currentProgress);
    });
  }

  bindInputOnChange() {
    this.element.addEventListener('input', () => {
      console.log('./time to seek to...',
        (100 * this.element.value / this.audioController.original.duration),
        this.audioController.original.duration,
        this.element.value
      );

      this.audioController.seekTo(
        (this.element.value / 100) * this.audioController.original.duration
      );
    });
  }

  setInputCurrentProgress(currentProgress) {
    if (this.element) {
      this.element.value = currentProgress;
    }
  }

  createElement(playing) {
    return html`
      <input type="range" min="0" max="100" value="0" class="progress anim ${playing ? 'anim-shown' : 'anim-hidden'}" />
    `
  }

  update(playing) {
    if (this.element.className) {
      this.element.className = this.element.className.replace('anim-hidden', '');
      this.element.className = this.element.className.replace('anim-shown', '');
      this.element.className = `${this.element.className} ${playing ? 'anim-shown' : 'anim-hidden'}`
    }

    return false;
  }
}

class PlayerComponent extends Component {
  constructor (song, emit) {
    super();
    this.song = song;
    this.emit = emit;

    this.vrx = false;
    this.playing = false;

    this.initAudioController(song);

    this.progressComponent = new ProgressComponent(this.audioController, this.playing);

    this.handlePlayerClick = this.handlePlayerClick.bind(this);
    this.handleVrxToggleClick = this.handleVrxToggleClick.bind(this);
  }

  initAudioController(song) {
    this.audioController = new window.AudioController(
      song.file,
      song.fileVrx,
    );
  }

  handleVrxToggleClick() {
    this.vrx = !this.vrx;

    if (this.playing) {
      this.playAudio();
    }

    console.log('handleVrxToggleClick');

    this.rerender();
  }

  handlePlayerClick() {
    if (this.playing) {
      this.emit('pause');
    } else {
      this.emit('play', this.song);
      this.playAudio();
    }
  }

  playAudio() {
    if (this.vrx) {
      this.audioController.playVirtual();
    } else {
      this.audioController.playOriginal();
    }
  }

  createElement(playing) {
    this.playing = playing;

    const playingNode = (() => {
      if (playing) {
        return html`<i class="fas fa-pause pause"></i>`;
      } else {
        return html`<i class="fas fa-play play"></i>`;
      }
    })();

    const vrxToggleNode = (() => {
      if (this.vrx) {
        return html`<div class="switch">
          <input type="checkbox" checked>
          <span class="slider round"></span>
        </div>`;
      } else {
        return html`<div class="switch">
        <input type="checkbox">
        <span class="slider round"></span>
      </div>`;
      }
    })();

    return html`
      <div class="card border">
        <div class="card-body">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-4 col-md-4 col-6 text">
                ${this.song.title}
              </div>
              <div class="col-lg-4 col-md-3 col-6 d-none d-md-block">
                ${this.progressComponent.render(playing)}
              </div>
              <div class="col-lg-2 col-md-2 col-2 text-center">
                <div class="player-container">
                  <span onclick=${this.handlePlayerClick}>
                    ${playingNode}
                  </span>
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-4">
                <div class="vrx-container">
                  <span id="vrx-label">VRX</span> <span onclick=${this.handleVrxToggleClick}>${vrxToggleNode}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  }

  update(playing) {
    if (this.playing === playing) {
      return;
    }

    if (!playing) {
      this.audioController.pauseAll();
    }

    return true;
  }
}

const songsComponents = window.songs.map((song) => {
  return new PlayerComponent(song, app.emitter.emit.bind(app.emitter));
});

function mainView (state, emit) {
  return html`
    <div>
      ${songsComponents.map((songComponent) => songComponent.render(state.playingSong === songComponent.song, state.vrx))}
    </div>
  `
}
