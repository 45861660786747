import $ from 'jquery';
import '@fortawesome/fontawesome-free-webfonts';
import '@fortawesome/fontawesome-free-webfonts/css/fa-solid.css';

window.$ = window.jQuery = $;

require('bootstrap/js/dist/collapse');

require('./scss/index.scss');
require('./js/demo-audio-app.js');

function implementOpenMenu() {
  const navbarOpenClass = 'navbar-open';

  document.getElementsByClassName('navbar-toggler')[0].onclick = () => {
    if (document.body.className.indexOf(navbarOpenClass) !== -1) {
      document.body.className = '';  
    } else {
      document.body.className = navbarOpenClass;
    }
  };

  document.querySelectorAll('section').forEach((el) => {
    el.onclick = () => {
      document.body.className = '';
    };
  });
}
implementOpenMenu();

function implementStickyMenu() {
  const navbarEl = document.getElementsByClassName('navbar-sticky')[0];
  const navbarPreScrollClass = 'pre-scroll';
  const navbarScrolledClass = 'scrolled';
  const navbarDarkClasses = 'navbar-dark navbar-stick-dark';
  const navbarLightClasses = 'navbar-light navbar-stick-light';

  window.addEventListener('scroll', function(e) {
    if (window.scrollY > 50 && window.scrollY < 200) {
      if (navbarEl.className.indexOf(navbarPreScrollClass) === -1) {
        navbarEl.className = `${navbarEl.className} ${navbarPreScrollClass}`;
      }
    } else if (window.scrollY > 200 && window.scrollY < 500) {
      if (navbarEl.className.indexOf(navbarScrolledClass) === -1) {
        navbarEl.className = `${navbarEl.className} ${navbarScrolledClass}`;
      }
      navbarEl.className = navbarEl.className.replace(navbarDarkClasses, navbarLightClasses);
    } else if (window.scrollY < 50) {
      navbarEl.className = navbarEl.className.replace(navbarScrolledClass, '');
      navbarEl.className = navbarEl.className.replace(navbarPreScrollClass, '');
      navbarEl.className = navbarEl.className.replace(navbarLightClasses, navbarDarkClasses);
    }
  });
}
implementStickyMenu();

function implementScrollableLinks () {
  let marginTop = -56;
  document.querySelectorAll('.scroll-link').forEach((el) => {
    el.onclick = (e) => {
      e.preventDefault();

      window.scrollBy({
        top: (document.querySelector(el.getAttribute('data-scrollto')).getBoundingClientRect().top + marginTop),
        left: 0,
        behavior: 'smooth'
      });

      //rid of classname
      document.body.className = '';
    };
  });
}
implementScrollableLinks();

function bindContactForm () {
  const $fullName = $('#form-input-full-name');
  const $email = $('#form-input-email');
  const $message = $('#form-input-message');

  const $formSuccessMsg = $('#form-success-msg');
  const $formWarningMsg = $('#form-warning-msg');
  const $formErrorMsg = $('#form-error-msg');

  function hideAllMsgs () {
    $formSuccessMsg.hide();
    $formWarningMsg.hide();
    $formErrorMsg.hide();
  }

  function disableFields() {
    $fullName.attr('disabled', 'disabled');
    $email.attr('disabled', 'disabled');
    $message.attr('disabled', 'disabled');
  }

  $('#contact-form').submit(function(e){
    e.preventDefault();

    if ($fullName.val().trim() === '' || $email.val().trim() === '' || $message.val().trim() === '') {
      hideAllMsgs();
      $formWarningMsg.fadeIn(300);
      return;
    }

    $.ajax({
      url: 'https://hooks.zapier.com/hooks/catch/3886334/lzgm5z/',
      type: 'post',
      data: $('#contact-form').serialize(),
      success: function(){
        hideAllMsgs();
        $formSuccessMsg.fadeIn(300);
        disableFields();
      },
      error: function() {
        hideAllMsgs();
        $formErrorMsg.fadeIn(300);
      }
    });
  });
}
bindContactForm();
